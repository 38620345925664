.css-1vyamtt-MuiStepLabel-labelContainer {
  color: rgb(255 255 255 / 0%) !important;
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 10px !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: white !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: white !important;
}

.boxUploadPage {
  background-size: 50%;
  background-position: center;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  @media (min-width: 800px) {
    width: 50%;
    padding: 3%;
  }
  @media (max-height: 550px) {
    height: auto;
  }
  padding: 5%;
  position: relative;
}

.boxContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  height: 75vh;
  @media (min-width: 800px) {
    width: 75vw;
  }
  @media (max-height: 550px) {
    height: auto;
  }
  width: 100%;
}
