.BoxReset {
  background-color: white;
  padding: 30px;
  width: 100%;
  border-radius: 10px;

  @media (max-width: 400px) {
    margin: 0px 10px;
  }

  @media (min-width: 800px) {
    width: 50%;
  }
}
