@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
.css-10hburv-MuiTypography-root {
  font-family: "Montserrat" !important;
}

.boxAdmin {
  min-width: calc(100% - 20px);
  min-height: 350px;
  background-color: #3e3e3e;
  border-radius: 20px;
  margin-top: 20px;
  @media (min-width: 600px) {
    min-width: 300px;
    margin: 0;
    scale: 0.9;
  }
}

.containerAdmin {
  // min-height: calc(100vh - 10px);
  display: flex !important;
  margin-top: 40px !important;
  align-items: center;
  justify-content: start;

  @media (min-width: 600px) {
    width: 610px !important;
    height: auto;
  }
  @media (min-height: 1000px) {
    margin-top: 20px !important;
  }
}

.adminOuterContainer {
  height: 100% !important;

  @media (min-width: 600px) {
    height: 100vh !important;
  }

  @media (max-height: 750px) and (min-width: 600px) {
    height: 100% !important;
  }
}
