.boxUser {
  border-bottom: 2px solid #b6b6b6;
  border-right: none;
  padding: 20px;

  @media (min-width: 1200px) {
    padding-right: 20px;
    border-right: 2px solid #b6b6b6;
    border-bottom: none;
  }
}

.MuiFormControlLabel-root {
  gap: 10px;
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
}
