.sideMenu4 {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  height: 79vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    position: fixed;
    width: 23.5%;
    margin-top: 70px;
  }
}

.relativeParent4 {
  margin-top: 20px;
  @media (min-width: 768px) {
    position: relative;
  }
}

.mapGeneratedMainIoT {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  @media (min-width: 800px) {
    overflow: hidden;
  }
  .mapContainerIoT {
    height: 100%;
    width: 100%;
    position: fixed;

    // .leaflet-top.leaflet-right {
    //   top: 5px;
    //   right: 60px;
    //   z-index: 999999;
    // }
  }

  .mapWrapperIoT {
    display: flex;
    justify-content: center;
    align-items: center;
    .topHeaderIoT {
      position: fixed;
      left: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 !important;
      z-index: 9999;
      top: 70px;
      width: 97%;

      @media (min-width: 769px) {
        width: calc(100% - 380px);
      }
    }
  }
  .leaflet-bottom {
    bottom: 38%;
    @media (min-width: 769px) {
      bottom: 60px;
    }
  }
  .leaflet-right {
    right: 0;
    @media (min-width: 769px) {
      right: 20px;
    }
  }
}
.dataTypeContainerIoT {
  position: absolute;
  z-index: 100;
  top: 70%;
  bottom: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 5px;
  width: 96% !important;
  left: 2%;
  margin-top: 60px;
  height: calc(100% - 140px);

  @media (min-width: 769px) {
    /* Styles for screens with a minimum width of 769px */
    padding: 0;
    width: 350px !important;
    bottom: 10px;
    overflow: hidden;
    position: fixed;
    top: 70px;
    left: 10px;
  }
}
