@font-face {
  font-family: "ProzaLibre";
  src: url("../fonts/Proza_Libre/ProzaLibre-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "ProzaLibre";
  src: url("../fonts/Proza_Libre/ProzaLibre-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "ProzaLibre";
  src: url("../fonts/Proza_Libre/ProzaLibre-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "ProzaLibre";
  src: url("../fonts/Proza_Libre/ProzaLibre-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "ProzaLibre";
  src: url("../fonts/Proza_Libre/ProzaLibre-ExtraBold.ttf");
  font-weight: 800;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
}
