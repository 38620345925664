.tabNav {
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  padding-top: 10px;

  @media (min-width: 800px) {
    /* Styles for screens with a minimum width of 768px */
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 0;
  }
}

.archiveText {
  display: none;
  @media (min-width: 800px) {
    display: flex;
  }
}

.rightSearchBar {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  @media (min-width: 800px) {
    width: auto;
  }
}

.rightBar {
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: start;
  width: 100%;
  @media (min-width: 800px) {
    width: auto;
  }
}

.leftBar {
  display: flex;
  width: 100%;
  @media (min-width: 800px) {
    width: auto;
  }
}

.leftBar1 {
  background-color: white;
  border-radius: 5px;
  display: flex;
  width: 100%;
  @media (min-width: 800px) {
    width: auto;
  }
}

.headerWithButton {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: calc(100%);

  @media (min-width: 800px) {
    width: calc(100% - 20px);
    border-radius: 10px;
  }
}
