.line1,
.line2,
.line3,
.line4,
.line5,
.line6,
.line7,
.line8,
.line9,
.line10 {
  fill: none;
  stroke-width: 2px;
  display: block;
}

.line1b,
.line2b,
.line3b,
.line4b,
.line5b,
.line6b,
.line7b,
.line8b,
.line9b,
.line10b {
  fill: none;
  stroke-width: 1.5px;
  display: block;
}

line {
  stroke: black;
}
.line1,
.line1b {
  stroke: #ffaf02;
}
.line2,
.line2b {
  stroke: #50b720;
}
.line3,
.line3b {
  stroke: #e21be9;
}
.line4,
.line4b {
  stroke: #1083fc;
}
.line5,
.line5b {
  stroke: #95c0ed;
}
.line6,
.line6b {
  stroke: #ff0000;
}
.line7,
.line7b {
  stroke: #ff55bb;
}
.line8,
.line8b {
  stroke: #f6fa70;
}
.line9,
.line9b {
  stroke: #fc4f00;
}
.line10,
.line10b {
  stroke: #e8d2a6;
}

.temperature {
  stroke: #ff0000;
}

.temperatureCircle {
  fill: #ff0000;
  background: #ff0000;
}

.dewPoint,
.cec {
  stroke: #00ff00;
}

.dewPointCircle,
.cecCircle {
  fill: #00ff00;
  background: #00ff00;
}

.heatIndex,
.diffuseOxygen {
  stroke: #0000ff;
}

.heatIndexCircle,
.diffuseOxygenCircle {
  fill: #0000ff;
  background: #0000ff;
}

.windChill,
.electricConductivity {
  stroke: #ffff00;
}

.windChillCircle,
.electricConductivityCircle {
  fill: #ffff00;
  background: #ffff00;
}

.humidity,
.salinity {
  stroke: #00ffff;
}

.humidityCircle,
.salinityCircle {
  fill: #00ffff;
  background: #00ffff;
}

.windSpeed,
.waterLevel {
  stroke: #ff00ff;
}

.windSpeedCircle,
.waterLevelCircle {
  fill: #ff00ff;
  background: #ff00ff;
}

.windDirection,
.ultrasonicWaterLevel {
  stroke: #ffa500;
}

.windDirectionCircle,
.ultrasonicWaterLevelCircle {
  fill: #ffa500;
  background: #ffa500;
}

.barometricPressure,
.nitrogen {
  stroke: #cc99ff;
}

.barometricPressureCircle,
.nitrogenCircle {
  fill: #cc99ff;
  background: #cc99ff;
}

.thi,
.phosphorus {
  stroke: #008000;
}

.thiCircle,
.phosphorusCircle {
  fill: #008000;
  background: #008000;
}

.rainfall,
.potassium {
  stroke: #ff6f61;
}

.rainfallCircle,
.potassiumCircle {
  fill: #ff6f61;
  background: #ff6f61;
}

.ph {
  stroke: #87ceeb;
}

.phCircle {
  fill: #87ceeb;
  background: #87ceeb;
}

.moisture {
  stroke: #800000;
}

.moistureCircle {
  fill: #800000;
  background: #800000;
}

// .electricConductivity {
//   stroke: #000080;
// }

// .electricConductivityCircle {
//   fill: #000080;
//   background: #000080;
// }

// .salinity {
//   stroke: #98fb98;
// }

// .salinityCircle {
//   fill: #98fb98;
//   background: #98fb98;
// }

// .waterLevel {
//   stroke: #ffffff;
// }

// .waterLevelCircle {
//   fill: #ffffff;
//   background: #ffffff;
// }

// .ultrasonicWaterLevel {
//   stroke: #ffffcc;
// }

// .ultrasonicWaterLevelCircle {
//   fill: #ffffcc;
//   background: #ffffcc;
// }

// .nitrogen {
//   stroke: #ff99cc;
// }

// .nitrogenCircle {
//   fill: #ff99cc;
//   background: #ff99cc;
// }

// .phosphorus {
//   stroke: #ffd900;
// }

// .phosphorusCircle {
//   fill: #ffd900;
//   background: #ffd900;
// }

// .potassium {
//   stroke: #4b0082;
// }

// .potassiumCircle {
//   fill: #4b0082;
//   background: #4b0082;
// }

// .ph {
//   stroke: #40e0d0;
// }

// .phCircle {
//   fill: #40e0d0;
//   background: #40e0d0;
// }

// .moisture {
//   stroke: #40e0d0;
// }

// .moistureCircle {
//   fill: #40e0d0;
//   background: #40e0d0;
// }

.lineYTotalWorker {
  stroke: brown;
}
.brush .selection {
  // background: "rgba(255, 255, 255, 0.8)";
  // backdrop-filter: blur(15px);
  // -webkit-backdrop-filter: blur(15px);
  stroke-width: 2px;
}
.chart-container {
  min-height: 200px;
  width: 100%;
  position: relative;
}
.y.axis .tick {
  display: none;
}
.tooltip {
  position: absolute;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  // pointer-events: none;
}

.avatarLintramax {
  .MuiAvatarGroup-root {
    .MuiAvatar-root {
      width: 30px;
      height: 30px;
    }
  }
}

.MuiDataGrid-menuList {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5px !important;
  .MuiMenuItem-root {
    margin: 5px !important;
  }
}
