.sideMenu {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 40vh;
  flex-direction: column;
  margin-bottom: 10px;
}
@media (min-width: 960px) {
  .sideMenu {
    position: fixed;
    width: 23.5%;
    height: calc(100vh - 140px);
    margin-bottom: 0;
  }
}

@media (min-width: 900px) {
  .relativeParent {
    position: relative;
  }
}/*# sourceMappingURL=index.css.map */