@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

*,
html,
body {
  font-family: "Montserrat", sans-serif;
}

.searchLeftBox {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  /* height: 30vh; */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

@media (min-width: 900px) {
  .searchLeftBox {
    height: calc(100vh - 140px);
    position: fixed;
    width: 23.5%;
    margin-bottom: 0;
  }
}
