.sideMenu {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 40vh;
  flex-direction: column;
  margin-bottom: 10px;

  @media (min-width: 960px) {
    position: fixed;
    width: 23.5%;
    height: calc(100vh - 140px);
    margin-bottom: 0;
  }
}

.relativeParent {
  //   margin-top: 10px;
  @media (min-width: 900px) {
    position: relative;
  }
}
