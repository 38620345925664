.custom-table {
  
  background-color: white;
  .MuiDataGrid-columnHeaders {
    background-color: rgb(72, 72, 72);
    color:  white;
  }

  .MuiDataGrid-row{
    background-color: white;
  }

  .MuiDataGrid-row:hover{
    background-color: grey;
  }

}

.leaflet-control-attribution{
  display: none;
}