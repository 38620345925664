

.digiMap{
  .border1 {
    width: 5px;
    height: 20px;
    border-radius: 20px;
    &.blue {
      background: #2b337d;
    }
    &.red {
      background: #e21f26;
    }
}
}