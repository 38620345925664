.makeStyles-root-1 {
  background: white;
}
.tabNav {
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  padding-top: 10px;
  width: 100%;

  @media (min-width: 800px) {
    /* Styles for screens with a minimum width of 768px */
  justify-content: space-between;
  
  display: flex;
  align-items: center;
flex-direction: row;
padding-top: 0;
  }
}