.chart-container {
  label {
    display: none;
  }
  .MuiTextField-root {
    width: 130px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  .MuiInputBase-root {
    color: white;
    font-weight: 600;

    fieldset {
      display: none;
    }
  }
}
