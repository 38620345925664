.MapGeneratedMain {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  @media (min-width: 800px) {
    overflow: hidden;
  }
  .mapContainer {
    height: 100%;
    width: 100%;
    position: fixed;

    .leaflet-top.leaflet-right {
      top: 5px;
      right: 60px;
      z-index: 999999;
    }
  }
  .dataContainer {
    position: absolute;
    z-index: 100;
    top: 70%;
    bottom: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 5px;
    width: 96%;
    right: 2%;

    @media (min-width: 769px) {
      /* Styles for screens with a minimum width of 769px */
      padding: 0;
      width: 350px;
      bottom: 10px;
      overflow: hidden;
      position: fixed;
      top: 70px;
      right: 10px;
    }
  }
  .mapWrapper {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    // .topHeader {
    //   background-color: rgba(255, 255, 255, 0.6);
    //   backdrop-filter: blur(10px);
    //   -webkit-backdrop-filter: blur(10px);
    //   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    //   position: fixed;
    //   left: 10px;
    //   width: 100%;
    //   display: flex;
    //   justify-content: space-between;
    //   border-radius: 10px;
    //   padding: 0 !important;
    //   z-index: 9999 !important;
    //   top: 70px;
    //   width: 97%;

    //   @media (min-width: 769px) {
    //     top: 70px !important;
    //     left: 10px !important;
    //     border-radius: 10px !important;
    //     width: calc(100% - 380px) !important;
    //   }

    //   @media (min-width: 0px) {
    //     top: 60px;
    //     left: 0px;
    //     border-radius: 0px;
    //     width: 100%;
    //   }
    // }
  }
  .MuiIconButton-root {
    padding: 5px;
  }
}

.topHeader {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0 !important;
  z-index: 9999 !important;
  top: 70px;
  width: 97%;

  @media (min-width: 769px) {
    top: 70px !important;
    left: 10px !important;
    border-radius: 10px !important;
    width: calc(100% - 380px) !important;
  }

  @media (min-width: 0px) {
    top: 60px;
    left: 0px;
    border-radius: 0px;
    width: 100%;
  }
}

.dataContainer {
  .blue {
    color: #2b337d;
  }
  .red {
    color: #e21f26;
  }

  .border {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    &.blue {
      background: #2b337d;
    }
    &.red {
      background: #e21f26;
    }
  }
}

.woi {
  fill: red;
  stroke: black;
  stroke-width: 2px;
}

.lah-woi {
  fill: blue;
  stroke: black;
  stroke-width: 1px;
}

.leaflet-bottom {
  bottom: 38%;
  @media (min-width: 769px) {
    bottom: 60px;
  }
}
.leaflet-right {
  right: 0;
  @media (min-width: 769px) {
    right: 370px;
  }
}

.adminMenu {
  display: none;
  @media (min-width: 769px) {
    align-items: center;
    gap: 15px;
    display: flex;
  }
}

.adminMenuSmall {
  display: flex;
  padding: 10px;
  @media (min-width: 769px) {
    display: none;
  }
}

.custom-tooltip {
  z-index: 100;
  &:hover {
    // Set higher z-index on hover
    z-index: 200;

    // Target another class and set its z-index
    .custom-marker {
      z-index: 1000 !important;
    }
  }
}

.custom-marker {
  z-index: 100 !important;
}
