@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
.gridProfile {
  background: white;
  border-radius: 10px;
  position: relative;
  @media (min-width: 900px) {
    margin-left: 10px;
  }
  min-height: 88vh;
}

.buttonProfile {
  border-radius: 10px !important;
  border: 1px solid black !important;
  width: 100%;
  padding: 10px !important;
  cursor: pointer;
  color: black !important;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.profileBox {
  background: #ceb889;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column !important;
  padding-top: 20px;
  position: relative;
  margin-bottom: 10px;
  margin-top: -10px;
  @media (min-width: 900px) {
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.customSwalError {
  z-index: "999";
}
